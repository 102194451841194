"use strict"

import React, { createElement, Component, Fragment } from 'react'
    
const e = createElement;

import { Typography } from '@mui/material'


const LBrace = (props) => '{';
const RBrace = (props) => '}';
    
class JsonComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { collapsed: false };
    }

    render() {
        var x = this.props.json || this.children;
        const { collapsed } = this.state;
        
        
        if (x===undefined || x===null)
            return 'null';
            
        try {
            var type = typeof this.props.json;
            if (type=="object" && Array.isArray(x))
                type = "array";
            switch (type)
            {
                case "object":
                    if (x.$ptr && (typeof x.$ptr)=="string" ) 
                        return <Typography component='span'  display='inline'><LBrace/>$ptr:<JsonLink uri={x.$ptr} onLink={this.props.onLink} /><RBrace/></Typography>;

                    if (collapsed)
                        return <Typography
                                component='span' display='inline'
                                className='collapsed'
                                onClick={ (ev)=>{ ev.preventDefault(); this.setState( {collapsed: false}); }}
                            >
                            <LBrace/>{ x.Description || '...' }<RBrace/>
                            </Typography>;

                    return <Fragment>
                            <Typography
                                component='span' display='inline'
                                key= 'openbracket'
                                onClick={(ev)=>{ ev.preventDefault(); this.setState( { collapsed: true }); }}
                                style={{ paddingRight: '20px', cursor: 'pointer' }}
                            >
                                <LBrace/>
                            </Typography>
                            <Typography component='span' display='block' className='jsonobject' key='objcont'>
                                { x.$uri && typeof(x.$uri)==="string"
                                    && <Typography display='inline' key='uri'>
                                        $uri: "<JsonLink uri={x.$uri} onLink={this.props.onLink}/>"
                                        </Typography>
                                }
                                {
                                    Object.keys(x)
                                        .filter(k=>k!=="$uri") // && k!=="$ref" )
                                        .filter(k=>typeof(x[k])!=="function")
                                        .map((k,i)=><Fragment key={i}>
                                            {(i>0) && <Typography component='span'>,<br/></Typography>}
                                            <Typography component='span' display='inline' className='label'>"{k}":</Typography>
                                            { (k==="$uri" || k==="$ptr") && typeof(x[k])==="string"
                                                ? <JsonLink uri={x[k]} onLink={this.props.onLink} />
                                                : ((k==="Data" && (typeof x[k])==="string")
                                                    ? <JsonImage value={x[k]}/>
                                                    : <JsonComponent json={x[k]} key={x} onLink={this.props.onLink} />
                                                ) }
                                            </Fragment>
                                            )
                                }
                                <RBrace/>
                            </Typography>
                        </Fragment>;

                    
                case "array":
                    if (collapsed)
                        return <Typography
                                component='span' display='inline'
                                onClick= { (ev)=>{ ev.preventDefault(); this.setState( {collapsed: false});  } }
                                >[...{x.length} items...]</Typography>;

                    return <Typography display='inline' onClick={ (ev)=>{ ev.preventDefault(); this.setState( { collapsed: true }); } } >
                        [
                            <Typography component='span' display='inline' className='jsonarray'>
                            {
                                Object.keys(x)
                                    .map( (z,i) => <Fragment key={"BR_"+i}>
                                        {  (i>0) && <br /> }
                                        <JsonComponent key={z} json={x[z]} onLink={ this.props.onLink } />
                                    </Fragment>)
                            }
                            </Typography>
                            ]
                            </Typography>;

                case "string":
                    if (x.substring(0,5)=="data:")
                    {
                        return <img src={x}/>;
                    }
                    return <Typography component='span' display='inline' className='jsonstring'>"{x}"</Typography>
                    
                    
                case "number":
                case "boolean":
                    return <Typography display='inline' className='jsonnumber'>{x}</Typography>;
                    
                case "undefined":
                    return 'undefined';
                    
                    
                default:
                    return <pre>{JSON.stringify(x, null, 3 )}</pre>;
                    
            }
        } catch (exc) {
            return <Typography>{exc.toString()}</Typography>;
        }
    }
}

class JsonImage extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return <img src={`data:image/png;base64${this.props.value}`}
            style={{ display: 'block', backgroundColor: 'transparent' }}/>;
    }
    
}

class JsonLink extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const { uri, onLink } = this.props;
        if (!uri)
            return '';
        return <Typography
            component='span'
            display='inline'
            className='jsonlink'
            onClick={()=> onLink && onLink(uri.toString())}
            >{uri}</Typography>
    }
}

export { JsonComponent }
