import React, { Component } from 'react'
import {SvgIcon} from '@mui/material'

export class Eagle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { width, height, position, fill, zIndex, onClick } = this.props;
    let style = {
      width: width || '200px', height: height || '200px', overflow: 'visible', fill: 'rgb(255,0,0,0.2)', transform: 'scale(1,-1)',
      position: position || 'absolute', fill, zIndex,
      ...this.props
    }
    return <SvgIcon viewBox="-50 -26 52 52" style={style} onClick={onClick}>
      <path d={
        "M -39.8756 14.18845 C -39.8756 14.18845 -38.95645 15.4907 -37.79787 15.2523 C -36.09426 14.90572 -32.37632 14.51298 -31.642 14.02274"
        + " C -30.90888 13.53526 -30.65084 13.0936 -31.21412 10.78332 C -31.82596 8.276325 -44.2035 -25.95173 -44.2035 -25.95173"
        + " C -44.2035 -25.95173 -39.92452 -25.95173 -39.92452 -25.95173 C -39.92452 -25.95173 -29.44075 9.500023 -28.8308 12.00564"
        + " C -28.26666 14.31627 -28.64614 14.94017 -29.37943 15.42938 C -30.11513 15.91858 -35.31826 16.75023 -37.02995 17.05615 C -38.74096 17.36208 -39.8756 14.18845 -39.8756 14.18845 Z"
        + " M -2.602081 2.897498 C -2.602081 2.897498 -6.086967 17.69212 -7.676878 21.23712 C -9.266618 24.78419 -12.50604 25.76122 -16.41967 26.1295"
        + " C -20.33226 26.49502 -26.72154 25.97619 -27.94317 25.73262 C -27.94317 25.73262 -30.6505 25.13214 -29.3815 23.07921"
        + " C -29.3815 23.07921 -29.77803 24.05107 -27.94317 24.23435 C -26.109 24.41763 -15.5317 24.81451 -12.01701 21.48172"
        + "C -8.501806 18.15066 -2.602081 2.897498 -2.602081 2.897498 Z"
        + " M -40.32484 13.5959 C -40.32484 13.5959 -40.4828 16.72335 -39.9948 18.80075 C -39.50525 20.88021 -36.41242 20.7486 -34.51676 20.93292"
        + " C -32.62092 21.1162 -23.63387 21.97058 -23.63387 21.97058 C -23.63387 21.97058 -33.79242 22.61378 -35.06746 22.64306 "
        + " C -40.32484 22.76674 -41.96833 22.41603 -42.1597 18.85346 C -42.37312 14.82993 -40.32484 13.5959 -40.32484 13.5959"} />
    </SvgIcon>;

  }
}
