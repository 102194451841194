
import React, { Component } from 'react'
import { Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Collapse, FormControlLabel, FormControl, FormLabel, FormGroup, Checkbox, FormHelperText } from '@mui/material'
import { BusyButton } from '../busybutton.js'


export const GrantType = {
  Implicit : 'implicit',
  AuthorizationCode : 'authorization_code',
  RefreshToken : 'refresh_token',
  ClientCredentials : 'client_credentials',
  DeviceCode : 'urn:ietf:params:oauth:grant-type:device_code'
};

export const ResponseType = {
  Token : 'token',
  Code : 'code'
};

export const AuthMethod = {
  None : 'none'
};

const allowedGrantTypes = [ GrantType.AuthorizationCode, GrantType.RefreshToken, GrantType.Implicit, GrantType.ClientCredentials, GrantType.DeviceCode ]
const allowedResponseTypes = [ ResponseType.Token, ResponseType.Code ]

const default_registration = {
  client_name: 'Topology PoC',
  token_endpoint_auth_method: AuthMethod.None,
  grant_types: [GrantType.AuthorizationCode, GrantType.RefreshToken],
  response_types: [ ResponseType.Token, ResponseType.Code ],
  contacts: ['joe.kelleher@amag.com'],
  software_id: '@symmetry/mapping',
  software_version: __VERSION__
}

const MobileDialog = (Dialog);
/**
function Fetch(  url, options )
{
  return new Promise((resolve,reject) => {
    const oReq = new XMLHttpRequest();
    oReq.open( options.method || 'GET', url );
    Object.getOwnPropertyNames(options.headers).forEach(  name =>
      oReq.setRequestHeader( name, options.headers[name] ) );
    oReq.onload = () => resolve( { ok: oReq.status===200, headers: oReq.getAllResponseHeaders() } );
    oReq.onerror = () => reject( {ok:false, headers: oReq.getAllResponseHeaders() });
    oReq.send();
  } );
}
*/

 export class RegisterForm extends Component {
        constructor(props) {
            super(props);
          const callbackUrl = new URL('/authed', window.location.href).toString();
          this.state = {
            urlError: false, apiServer: '', error: false,
            ...default_registration,
            redirect_uris: [callbackUrl],
            client_uri: new URL('/info', window.location.href).toString(),
            };
          this.handleSubmit = this.handleSubmit.bind(this);
          this.handleLegacySubmit = this.handleLegacySubmit.bind(this);
        }

   handleLegacySubmit( event ) {
    event.preventDefault();
    this.setState({ submitted: true });

    const { client_name, username, password, apiServer } = this.state;

    // fetch forces headers to lower case, but the service should now handle this
    fetch(apiServer, {
      method: 'POST',
      headers: {
        clientUserName: username,
        clientPassword: password,
        clientDescription: client_name,
        clientAction: 'register',
        clientType: 'SymmetryWEB 3.0'
      }
    })
      .then(r => {
        this.setState({ submitted: false });
        if (r.ok)
        {
          const clientToken = r.headers.get("clientToken");
          let clients = JSON.parse(localStorage.clients||"[]");
          clients.push({ url: apiServer, registration: { clientToken} });
          localStorage.clients = JSON.stringify(clients);
          if (onDone)
            onDone(reg);
        } })
        .catch( r => {
          this.setState({submitted:false, error: 'Failed connect to Legacy API server'});
        })
   }

   handleSubmit(event) {
     event.preventDefault();

     const { client_name, username, password, apiServer, redirect_uris, token_endpoint_auth_method,
      grant_types, response_type, client_uri, contacts,
      software_id,
      software_version } = this.state;
     const { onDone, onError } = this.props;


     const registration = {
       redirect_uris,
       client_name,
       token_endpoint_auth_method,
       grant_types,
       response_type,
       client_uri,
       contacts,
       software_id,
       software_version
     }


     try {
       new URL(apiServer);
     }
     catch (ex) {
       apiServer = "https://" + apiServer;
     }

     this.setState({ submitted: true });

     const regUrl = new URL('./oauth/register', apiServer).toString();

     fetch(regUrl, {
       method: 'POST',
       headers: {
          Authorization: 'Basic ' + btoa(username+':'+password)
       },
       body: JSON.stringify(registration, null, 3)
     })
       .then(r => {
         this.setState({ submitted: false });
         if (r.ok) {
           r.json().then(reg => {
             let clients = JSON.parse(localStorage.clients||"[]");
             clients.push({ url: apiServer, registration: reg });
             localStorage.clients = JSON.stringify(clients);
             if (onDone)
               onDone(reg);
           });
         }
         else if (r.status==400) {
           r.json().then( regerror => {
             this.setState( {error:regerror.error_description});
           });
         }
         else if (r.status==401) {
           // This is what we get from something without OAuth
           fetch( apiServer, {
            method: 'GET',
            headers: { 
              "clientAction": 'register',
              "clientDescription": registration.client_name,
              "clientUserName": username,
              "clientPassword": password,
              "clientType": 'SymmetryWEB 3.0'
            }}
            ).then( oldreg => {
              if (oldreg.ok) {
                console.log(oldreg);
              }
            });
         }
       })
       .catch(ex => {
         this.setState({ submitted: false, error: 'Failed connect to API server' });
         if (onError)
           onError(ex);
       });
   }


   componentDidUpdate( prevProps, prevState )
   {
      const {apiServer} = this.state;

      if (apiServer!=prevState.apiServer)
      {
        fetch( apiServer )
          .then( resp => resp.json() )
          .then( j => { if (j.errorMsg==="Invalid credentials.") {
             this.setState( {legacy:true});
          }
        });
      }
   }



   render() {
     const { apiServer, submitted, error,
       /*redirect_uris,
       token_endpoint_auth_method,
       client_uri, */
       client_name,
       grant_types,
       response_types,
       contacts,
       software_id,
       software_version,
       username,
       password,
       legacy
     } = this.state;

     let urlError = undefined;
     let url = undefined;

       try {
         if (apiServer)
           url = new URL(apiServer);
       }
       catch (exc) {
         try {
           url = new URL('https://' + apiServer)
         }
         catch (exc2) {
           urlError = exc.message;
         }
     }

     if (url) {
       if (url.protocol !== 'https:')
         urlError = 'Server must use https';
       else if (url.username || url.password)
         urlError = 'No credentials should be supplied in the url';
       else if (url.search || url.hash)
         urlError = "No parameters should be included";
     }


     const commonProps = {
       spellCheck: false, autoComplete: 'off', autoCorrect: 'off', margin: 'normal',
       variant: 'outlined', color: 'primary',
       fullWidth: true
       //style:{ minWidth: '30em' }
     }





     return <MobileDialog id='registerForm' maxWidth='md' open>
       <form onSubmit={legacy ? this.handleLegacySubmit : this.handleSubmit }>
            <DialogTitle>Register</DialogTitle>
            <DialogContent margin='normal' dividers>
           <Grid container direction='column'>
             <Grid item>
                <TextField
               onChange={(e, v) => this.setState({ error: false, apiServer: e.target.value })}
                 value={apiServer} required
                 {...commonProps}
               label='API Server URL'
                  error={(urlError||error)&&true} // the true is not unnecessary
                  helperText={urlError||error|| 'Enter the URL (web address) of the Symmetry API server'}
               />
             </Grid>
             <Grid item>
                <TextField
               onChange={(e, v) => this.setState({ username: e.target.value })}
                 value={username} required
                 {...commonProps}
               label='Username'
               />
             </Grid>
             <Grid item>
                <TextField
               onChange={(e, v) => this.setState({ password: e.target.value })}
                 value={password} required
                 {...commonProps}
                 InputProps = {{ type: 'password' }}
               label='Password'
               />
             </Grid>
             <Grid item>
                   <TextField
                     onChange={(e, v) => this.setState({ error: false, client_name: e.target.value })}
                     value={client_name} {...commonProps}
                     label='Client Name'
                     helperText={'The name by which this client will be known'}
                   />
              </Grid>
             { !legacy && <Grid item>
               <Collapse in>
                 <Grid item>
                   <TextField
                     onChange={(e, v) => this.setState({ error: false, software_id: e.target.value })}
                     value={software_id} {...commonProps}
                     label='Software ID'
                     helperText={'The identifier of this software'}
                   />
                 </Grid>
                 <Grid item>
                   <TextField
                     onChange={(e, v) => this.setState({ error: false, software_version: e.target.value })}
                     value={software_version} {...commonProps}
                     label='Software Version'
                     helperText={'The version of this software'}
                   />
                   </Grid>
                   <Grid item>
                     <TextField
                       onChange={(e, v) => this.setState({ error: false, contacts: e.target.value.split('\n') })}
                       multiline
                       value={contacts.join('\n')} {...commonProps}
                       label='Contacts'
                       helperText={'Contact for support etc.'}
                     />
                   </Grid>
                   <Grid item>
                     <FormControl {...commonProps}>
                       <FormLabel>Grant Types</FormLabel>
                       <FormGroup>
                         {
                           allowedGrantTypes.map(gt =>
                             <FormControlLabel key={gt} control={<Checkbox checked={grant_types.includes(gt)} name={gt} />} label={gt}
                               onChange={(e, v) => this.setState({ grant_types: grant_types.filter(x => x != gt).concat(e.target.checked ? [gt] : []) })}
                             />
                           )
                         }
                       </FormGroup>
                       <FormHelperText>OAUTH2.0 Grant Types (see RFC 6749)</FormHelperText>
                     </FormControl>
                   </Grid>
                   <Grid item>
                     <FormControl {...commonProps}>
                       <FormLabel>Response Types</FormLabel>
                       <FormGroup>
                         {
                           allowedResponseTypes.map(rt =>
                             <FormControlLabel key={rt} control={<Checkbox checked={response_types.includes(rt)} name={rt} />} label={rt}
                               onChange={(e, v) => this.setState({ response_types: response_types.filter(x => x != rt).concat(e.target.checked ? [rt] : []) })}
                             />
                           )
                         }
                       </FormGroup>
                       <FormHelperText>OAUTH2.0 Response Types (see RFC 6749)</FormHelperText>
                     </FormControl>
                   </Grid>
                 </Collapse>
               </Grid>
          }
              </Grid>
            </DialogContent>
            <DialogActions>
           <BusyButton type='submit' busy={submitted} variant='contained' color='primary'>Register</BusyButton>
            </DialogActions>
          </form>
     </MobileDialog>;
        }
    }
