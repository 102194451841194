import React, { Component, Fragment, useState, useEffect } from "react";
import { Snackbar, SnackbarContent, Dialog, DialogTitle, DialogContent, Typography, Button } from '@mui/material'
import { withRouter, Redirect } from 'react-router-dom'
import { JsonComponent } from "../JsonDisplay";
import { GrantType } from "./Register";
import { WorkingDialog, LogonForm } from "../symmetryreact";


export const OAuthLogin = withRouter(props => <OAuthLoginInternal {...props} />)
export const AuthCallback = withRouter(props => <AuthCallbackInternal {...props} />)

function randomState() {
    const base = 36;
    const len = 12;

    return Math.floor(Math.random() * Math.pow(base, len)).toString(len);
}


function OAuthLoginInternal({ client, onError, location }) {
    const [oauthError, setOauthError] = useState(false);
    const [checkingOauth, setCheckingOauth] = useState(false);
    const referer = location;
    const state = randomState();
    sessionStorage.setItem(state, JSON.stringify({ referer }));

    window.location.href = "/authenticate?redirect_uri=" + new URL('/authed', window.location.href).toString()
        + `&state=${state}&response_type=code`;

    return null;

    //const { url, registration } = client;
//    const { client_id, response_types } = registration;

  //  var response_type = response_types &&  response_types[0];

            /*
    useEffect( async () => {
        if (response_type) {
            try {
                const resp = await fetch(new URL('oauth/authenticate', url), { method: 'OPTIONS' });
                if (resp.ok) {
                    const referer = location;
                    const state = randomState();
                    sessionStorage.setItem(state, JSON.stringify({ referer }));
                    let authUrl = new URL('oauth/authenticate?redirect_uri=' + new URL('/authed', window.location.href).toString()
                        + `&client_id=${client_id}&state=${state}&response_type=${response_type}`, url);
                    window.location.href = authUrl;
                } else {
                    setCheckingOauth(false);
                }
            } catch (ex) {
                setCheckingOauth(false);
                setOauthError(ex.message);
                if (onError)
                    onError(ex);
            }
        }
    }, [response_type]);
            */

    const { grant_types } = registration;
    const grant_type = grant_types[0];
    if (oauthError)
      return <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open >
        <SnackbarContent severity='error' color='error'
          message={
            <Fragment>
              <Typography variant='h5'>OAUTH not available</Typography>
              <Typography>{oauthError}</Typography>
              <JsonComponent json={registration} />
            </Fragment>
          } /></Snackbar>;


    switch (grant_type) {
      case "legacy":
        return <LogonForm {...props}/>;

      case GrantType.ClientCredentials:
        const authParams = { ...props };
        return <AuthCallback {...authParams} />;

      case GrantType.DeviceCode:
        return <Dialog open>
          <DialogTitle>Device code</DialogTitle>
          <DialogContent>
            <Typography>Not yet implemented</Typography>
            <Typography>Visit this url and enter this code...</Typography>
          </DialogContent>
        </Dialog>;
    }

    return <WorkingDialog>Checking for OAUTH support...</WorkingDialog>;
}


function AuthCallbackInternal({  onError, onAuth, location }) {
    const [referer, setReferer] = useState();
    const [shownInfo, setShownInfo] = useState(false);

    const searchParams = Object.fromEntries(new URLSearchParams(location.search.substring(1)).entries());
    const hashParams = Object.fromEntries(new URLSearchParams(location.hash.substring(1)).entries());

    const params = hashParams.access_token ? hashParams : searchParams;

    const { access_token, code, state, error, error_description } = params;
    const grant_type = GrantType.AuthorizationCode;


    useEffect(() => {
        async function fetchData() {
            try {
                //const tokenurl = new URL('oauth/token', url).toString();
                const resp = await fetch('/token', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({
                        //client_id,
                        code, grant_type
                        //                        , url: tokenurl
                    }).toString(),
                });
                if (resp.ok)
                    resp.json().then(authInfo => {
                        onAuth({ ...authInfo, state });
                        //                        this.setState(savedState);
                    });
            }
            catch (ex) {
                setOauthError(ex.message);
                if (onError)
                    onError(ex);
            }
        }
        if (access_token) {
            onAuth(params);
        //            this.setState(savedState);
        } else {
            fetchData();
        }
    }, [access_token, error_description]);

    if (error_description)
        return;


    if (!shownInfo)
    {
      return <Fragment>
        <Typography variant="h3">Auth Callback</Typography>
          <JsonComponent json={{ onError, onAuth, location, searchParams}} />
        <Button onClick={()=>setShownInfo(true)}>Done</Button>
      </Fragment>
    }


    if (error_description)
      return <Typography variant="h6">{error_description}</Typography>;

    return referer
      ? <Redirect to={referer} />
      : <WorkingDialog>Retrieving access token...</WorkingDialog>
      ;
}
