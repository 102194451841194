import React, { useContext, useEffect, useState, createContext } from "react";
import { useSymmetry } from "../symmetryreact";

export const AlarmContext = createContext(
    {
      alarms: []
    }
    )
    
export function AlarmsProvider( props )
{
    const { session } = useSymmetry();
    const [alarmUrl,setAlarmUrl] = useState("Alarms?sort=USN&exclude=TypeMeta&longpoll=60&limit=100");
    const [alarms,setAlarms] = useState([]);
    const [pollingSequence,setPollingSequence] = useState(0);

    const updateState = (uri, AlarmState) => {
    session.fetch(uri, "PUT", { AlarmState })
        .then(cleared => { /* ignore response*/ })
        .catch(failed => { /* ignore errors */ });
    }

    const addStateHandler = (alarm) => {
    const realState = alarm.AlarmState;
    Object.defineProperty(alarm, 'AlarmState',
        {
        set: (newstate) => { updateState(alarm.$uri, newstate) },
        get: () => realState
        });
        return alarm;
    }

    const processAlarms = (als)=>{
    if (als.length>0)
    {
        setAlarms(upToDateAlarms=>
        als.reduce( (currentList,updated)=>currentList.filter( cal => cal.$uri!=updated.$uri), upToDateAlarms )
        .concat( als.filter( al => al.AlarmState!="Cleared").map(addStateHandler))
        );
    }
    }

    useEffect( ()=> {
    /* Perhaps don't use effect for this as change in state can cause re-rendering? */
    if (alarmUrl)
    {
        const request = session.fetch( alarmUrl );
        request.then( processAlarms );

        request.links.then(links => {
        if (links && links.Next && links.Next!=alarmUrl)
        {
            setAlarmUrl(links.Next);
        }
        else
        {
            setPollingSequence( ps => ps+1 );
        }
        });
    }
    }, [session,alarmUrl,pollingSequence])


    return <AlarmContext.Provider value={{alarms}}>
    {props.children}
    </AlarmContext.Provider>
}

export const useAlarms =() => useContext(AlarmContext);
