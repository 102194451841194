"use strict"

import React, { Fragment, Component, lazy, Suspense, useEffect, useState, createContext, useContext, useCallback } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { Session } from './symmetry.js'
import { Eagle } from './eagle.js'
import { RegisterForm } from './symmetry/Register.js'
import { OAuthLogin, AuthCallback } from './symmetry/OAuthLogin.js'
import { BusyButton } from './busybutton.js'
const Maps = lazy(() => import('./Mapping/Maps.js'));
import {
  TextField, Button, Grid, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress, Snackbar, SnackbarContent

} from '@mui/material'

import { AlarmsProvider } from './symmetry/Alarms'
import { ActivityProvider } from './symmetry/Activity'
import 'regenerator-runtime/runtime'
import { Authenticated } from './symmetry/auth.js'
const MobileDialog = (Dialog);



const Paths = {
  root: '/',
  register: '/register',
  authed: '/authed',
  select: '/select',
  logon: '/logon'
};


const LazyLoggedOn = lazy(() => import('./LoggedOn'));

const LoggedOnWithStyles = ((props) =>
  <Suspense fallback={
    <WorkingDialog>Loading client code...</WorkingDialog>
  }>
    <LazyLoggedOn {...props} />
  </Suspense>
);

function unique(item, index, array) {
  const itemAsString = JSON.stringify(item);
  return array.slice(0, index)
    .filter(i2 => JSON.stringify(i2) == itemAsString)
    .length == 0;
}

export function SymmetryReact() {
    const { session } = useSymmetry();
    const [systemDetails, setSystemDetails] = useState();

    useEffect(() => {
        async function getDetails() { setSystemDetails(await session.systemDetails); }
        getDetails();
    }, []);

    if (!systemDetails) {
      return <WorkingDialog>Retrieving initial information...</WorkingDialog>
    }

    return <LoggedOnWithStyles session={session} />;
}

export function WorkingDialog(props) {
  return <MobileDialog open>
    <DialogContent margin='normal'>
      <Grid container direction='column'>
        <Typography>{props.children}</Typography>
        <LinearProgress />
      </Grid>
    </DialogContent>
  </MobileDialog>
}


export const SessionContext = createContext(
  { session: undefined, company: undefined, changeCompamy: () => { }
   })

export function SessionProvider( { session, company:initCompany, children} )
{
  const [company,setCompany] = useState( initCompany );
  const [loggedOnUser, setLoggedOnUser] = useState();
  const [root,setRoot] = useState();
  
  useEffect( ()=> { 
    session.root.then( setRoot(root) );
    session.loggedOnUser.then( setLoggedOnUser);
   }, [session] );

  useEffect( ()=> { if (loggedOnUser) setCompany( loggedOnUser.Company ) }, [loggedOnUser]);
  useEffect( ()=> { if (company) session.company=company.Description; }, [company]);


  const changeCompany = (comp) => {
    setCompany(comp);
    session.company = comp && comp.Description;
  }

  return (session && loggedOnUser && company && setCompany)
    ? <SessionContext.Provider value={{session, loggedOnUser, company, changeCompany}}>
        <AlarmsProvider>
          <ActivityProvider>
          {children}
          </ActivityProvider>
        </AlarmsProvider>
      </SessionContext.Provider>
    : <WorkingDialog>Starting session...</WorkingDialog>;
  }

export const useSymmetry= () => useContext(SessionContext);



export const ErrorReport = ({ Children }) => <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open >
  <SnackbarContent severity='error' color='error'
    message={
      <Fragment>
        <Typography variant='h5'>Error</Typography>
        <Typography>{Children}</Typography>
      </Fragment>
    } /></Snackbar>;




export function LogonForm( { checkOauth, client, onAuth, onSession, onNewHost }) {
  const { session } = useSymmetry();
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ error, setError ] = useState();
  const [ authenticating, setAuthenticating ] = useState('');
  const [ oauthErrorerror, setOAuthError ] = useState();
  const [ checkingOauth, setCheckingOauth ] = useState(checkOauth);

  function handleSubmit(event) {
    const { registration } = client;

    event.preventDefault();

    setAuthenticating(true);

    fetch( client.url, {
      method: 'POST',
      headers: {
        clientAction: 'logon',
        userusername: username,
        userpassword: password,
        clienttoken: registration.clientToken,
        clientType: 'SymmetryWEB 3.0'
    } }
      ).then( r => {
        if (r.ok)
        {
          const userToken = r.headers.get("userToken");
          onAuth( { token_type: 'Legacy',  access_token: userToken });
        }
        else
        {
          r.json().then( setError );
        }
      }).catch( ex => {
        setError( {errorCode:1, errorMsg: ex.message });
      })
      .finally( ()=> setAuthenticating(false) );

  }

  function onSession(session) {
    if (session.errorCode)
      setError( session );
    else
      onSession(session);
  }


    const { url } = client;

    if (session)
      return <Redirect to={Paths.root} />;


    if (!client)
      return <Typography>Something wrong: no user</Typography>;

    const hostname = new URL(client.url).hostname.split('.')[0];
    // Have a user or at least a host
    return <MobileDialog open BackdropProps={{ className: "logonbackdrop " + hostname }}>
      <DialogTitle>Logon</DialogTitle>
      <form onSubmit={ handleSubmit}>
      <DialogContent dividers margin='normal'>
        <Grid container direction='column'>
          <Eagle fill='#e4002b0a' />
          <Typography variant='h6'>{url}</Typography>
          <Typography variant='h6'>{client.name||'Unnamed'}</Typography>
          <TextField autoFocus key='userprompt' label='Username' margin='normal'
            error={!!error}
            InputProps={{
              onChange: (e) => {
                setUsername( e.target.value );
                setError(undefined);
              },
              key: 'username',
              value: username,
              spellCheck: false,
              autoComplete: 'off',
              autoCorrect: 'off'
            }} />
          <TextField key='passwordprompt' label='Password'
            error={!!error}
            helperText={error ? error.errorMsg : ''}
            margin='normal'
            InputProps={{
              type: 'password',
              key: 'password',
              onChange: (e) => { setPassword(e.target.value); setError(false); },
              value: password,
              spellCheck: false,
              autoComplete: 'off',
              autoCorrect: 'off'
            }} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <BusyButton color='primary'
          busy={authenticating}
          tabIndex={0}
          variant='contained'
          type='submit'
          >Logon</BusyButton>
        <Button key='newHost'
          tabIndex={0}
          onClick={onNewHost}>Use another connection
              </Button>
      </DialogActions>
      </form>
    </MobileDialog>;
}


const container = document.getElementById("reactStuff");
const root = createRoot(container);

root.render(
  (<Authenticated>
        <Suspense fallback={'Loading...'}>
            <BrowserRouter>
                <Switch>
                  { /*
                    <Route exact path="/">
                        <Maps />
                    </Route>
  */ }
                    <Route path="/maps">
                        <Maps />
                    </Route>
                    <Route>
                        <SymmetryReact  />
                    </Route>
                </Switch>
            </BrowserRouter>

    </Suspense>
    </Authenticated>
    ));


