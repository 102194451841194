import React, { useContext, useEffect, useState, createContext } from "react";
import { useSymmetry } from "../symmetryreact";

export const ActivityContext = createContext(
    {
      activitys: []
    }
    )
    
export function ActivityProvider( props )
{
    const { session } = useSymmetry();
    const [activityUrl,setActivityUrl] = useState("Activity?sort=Id&include=What.*,Where.Description,Where.Type,When,Who.Person.Name.*&longpoll=60&limit=20");
    const [activity,setActivity] = useState([]);
    const [pollingSequence,setPollingSequence] = useState(0);
    const maxActivityCount = 200;

    const processActivity = (newAct)=>{
        if (newAct.length>0)
        {
            setActivity(upToDateActivity=> upToDateActivity.slice( Math.max( 0, upToDateActivity.length+newAct.length-maxActivityCount))
                .concat( newAct ) );
        }
    }

    useEffect( ()=> {
    /* Perhaps don't use effect for this as change in state can cause re-rendering? */
    if (activityUrl)
    {
        const request = session.fetch( activityUrl );
        request.then( processActivity );

        request.links.then(links => {
        if (links && links.Next && links.Next!=activityUrl)
        {
            // There seems to be and error with the expand field being ...expanded
            // i.e. duplicate values are entered. This removes them
            const [base, searchString] = links.Next.split('?');
            const params = new URLSearchParams( searchString );
            const expand = params.get("expand");
            const expandBits = expand.split(',');
            const pruned = expandBits.filter((item, index) => expandBits.indexOf(item) === index);
            params.set("expand", pruned )
            setActivityUrl(base + "?" + params.toString() );
        }
        else
        {
            setPollingSequence( ps => ps+1 );
        }
        });
    }
    }, [session,activityUrl,pollingSequence])


    return <ActivityContext.Provider value={{activity}}>
        {props.children}
    </ActivityContext.Provider>
}

export const useActivity =() => useContext(ActivityContext);
