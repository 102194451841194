"use strict"

import React from 'react'
    

    class Ptr  {
        static fixup( obj, found )  {
            if (!obj)
                return obj;
                
            if (!found)
                found = [];
                
            // If it's a point resolve if po
            if (obj.$ptr)
                return found[obj.$ptr] || obj;
                
            Object.keys(obj).forEach( k => {
                let memb = obj[k];
                if (typeof memb=="object")
                    obj[k] = Ptr.fixup( memb, found );
            } );
            
            // Add to map only at the end to avoid loops
            if (obj.$uri)
                found[obj.$uri] = obj;
                
            return obj;
        }
    }

    class Base64 {
        static decode( str ) 
        {
            const b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
            let decode  = "";
            var encbytes = [];
            
            encbytes = Array.from(str).map( x => b64.indexOf(x));
            for (var i=0; i<encbytes.length; i+=4) {
                var acc = 0;
                var j;
                for (j=0; j<4 && i+j<encbytes.length; j++)
                    acc = acc*64+encbytes[i+j];
                var ditch = 4-j;
                for ( ; j<4; j++)
                    acc *= 64;
                for (j=2; j>=ditch; j--)
                    decode += String.fromCharCode((acc>>(j*8))&0xff);
            }
            decode = decode.replace('\0','');
            return decode;
        }

    }


    export class Session
    {
        constructor( client, authInfo )
        {
          this.client = client;
          this.authInfo = authInfo;
          this.fetchQueue = [];
          this.outstanding = 0;
          this.maxOutstanding = 4;
          this.useAuthorization = true;



          if (authInfo !== null) {
                this.populateForLegacy();
          } else {
                this.expires_at = Date.now() + authInfo.expires_in * 1000;
          }
        this.setTimeout();
        this.root = this.fetch(".?exclude=TypeMeta,TemplateCredentials,AvailableGroups&expand=Groups,Credentials");
        this.systemDetails = this.root.then(root => Promise.resolve(root.SystemDetails));
        this.loggedOnUser = this.root.then(root => Promise.resolve(root.ConnectionDetails.Account));
        }
        

        

        setTimeout()
        {
            if (this.expires_at)
            {
          let diff = (this.expires_at - Date.now());




          /** TODO remove after testing  *
          const testLimit = 2 * 60 * 1000;

          if (diff>  testLimit) {
            diff = testLimit;
            this.expires_at = Date.now() + diff;
          }
        **/

          clearTimeout(this.timeout);
          clearTimeout(this.timeoutWarning);

          this.timeout = setTimeout( () => this.onLogoff(), diff );
          this.timeoutWarning = setTimeout( ()=>this.renewToken(), diff*0.9 );
        }
      }

      populateForLegacy()
      {
          if (!this.authInfo.expires_in)
          {
              try {
                  const tokenInfo = JSON.parse(Base64.decode( this.authInfo.access_token.split(".")[1]));
                  this.expires_at = new Date(tokenInfo.nta*1000);
              }
              catch (ex)
              {
                  console.log( "Failed to parse expiry time" );
              }
          }
      }
        renewLegacyToken() {
                console.log("Renew!");
        fetch(this.client.url+"/?exclude=*", { headers: this.authorizationHeaders() } )
          .then(resp => {
            const userToken = resp.headers.get("userToken");
            this.authInfo.access_token = userToken;
            this.populateForLegacy();
            this.setTimeout();
          })
          .catch((ex)=>{
                  console.log( {failedToRenew:ex} );
                  this.onLogoff()
          });

      }

      renewToken() {
        const { token_type, refresh_token } = this.authInfo;

        if (token_type=="Legacy")
        {
            this.renewLegacyToken();
            return;
        }

        if (!refresh_token)
          return;
        console.log("RenewToken()");
        console.log(this.authInfo);
        fetch('/token',
          {
            headers: this.authorizationHeaders(),
            method: 'POST',
            body: new URLSearchParams({
              grant_type: 'refresh_token',
              refresh_token: refresh_token
            })
          }
        )
          .then(resp => {
            if (resp.ok) {
              resp.json().then(authInfo => {
                this.authInfo = authInfo;
                this.expires_at = Date.now() + authInfo.expires_in * 1000;
                this.setTimeout();
              }
            )
            } else {
              this.onLogoff();
            }
          })
          .catch(err => console.log(err));
      }

      authorizationHeaders() {
        const {token_type, access_token } = this.authInfo;

        return new Headers( token_type=="Legacy"
            ?  { 'usertoken': access_token, 'Authorization': `Bearer ${access_token}` }
            : { 'Authorization': `${token_type} ${access_token}` }
            );
        }

        fetch(path, method, body, signal) {
            if (body && body.constructor && (body.constructor.name=="Object"||body.constructor.name=="Array"))
                body = JSON.stringify(body);
                
            let uriWithCompany = new URL(path + (this.company && !path.includes("company=")
                            ? (path.includes("?")?"&":"?") + "company=" + this.company
                            : "" ),
                            this.client.url)
                            .href;
                
          let mainFetch =
            fetch(uriWithCompany, { headers: this.authorizationHeaders(), method, body, signal })
              .then(r => {
                if (this.useAuthorization && r.status == 401) {
                  this.useAuthorization = false;
                  // Failed authorization - possibly because it doesn't handle Authorization
                  return fetch(uriWithCompany, { headers: this.authorizationHeaders(), method, body, signal });
                }
                return r;
              });
            
            
            let resultFetch = mainFetch
                .then( r => {
                    var contentType = r.headers.get("content-type");
                    if (r.ok) {
                       
                        if (r.status===204)
                            return null;    // No content
                    
                        if (contentType===null || contentType.includes("json"))
                            return r.json().then( Ptr.fixup );

                        if (contentType.includes("image"))
                            return r.blob();

                        throw "Response type is "+ contentType;
                    }
                    else {
                        if (contentType.includes("json"))
                            return r.json().then( j => { throw j;});
                        throw { errorCode: r.status, errorMsg: r.statusText };
                    }
                })
                .catch( err => {
                    console.log( "Failed to fetch");
                    console.log( err );
                  });
    
                
            resultFetch.links = mainFetch.then( r => {
                    if (r.ok) {
                        var contentType = r.headers.get("content-type");

                        var newUserToken = r.headers.get("userToken");
                        if (newUserToken)
                            this.authInfo.access_token = newUserToken;
                    
                        if (contentType && contentType.includes("json"))
                        {
                            return this.parseLinks(r.headers.get("link"));
                        }
                    }
                    return undefined;
                })
                .catch( err => {;} );

            return resultFetch;
        }
        

        fetchLater( path, method, body) {
            var later = new Promise( (s,f) => {
                this.fetchQueue.push( {
                    Succeed: s, Fail: f, path: path, method: method, body: body } );
            });
            
            this.fetchNext();
            
            return later;
        }
        
        clearLaterQueue() {
            this.fetchQueue = [];
        }
        
        fetchNext()
        {
            if (this.fetchQueue.length===0 || this.outstanding>=this.maxOutstanding )
                return;
                
            this.outstanding++;
            let prom = this.fetchQueue.shift();
            
            let realfetch = this.fetch( prom.path, prom.method, prom.body );
            
            realfetch
                .then( x => { this.outstanding--; this.fetchNext(); } )
                .catch( x => { this.outstanding--; this.fetchNext();} );
                
            realfetch.then( prom.Succeed ).catch( prom.Fail );
        }
        
        parseLinks(linkhdr)
        {
        
            var links = [];
        
            if (linkhdr!==null)
            {
                var tmplinks = linkhdr.split(",").map( (x) => x.trim() );
        
                for (var i in tmplinks)
                {
                    var link = tmplinks[i];
                    var bits = link.split(";").map( (x) => x.trim() );
        
                    var url = bits[0].replace( /^</, "" ).replace(/>$/, "").replace( new RegExp( "^"+this.client.url), "" );
                    //url = decodeURI( unescape(url ));
        
                    var args = bits[1].split("=");
                    if (args[0]=="rel")
                        links[ args[1].replace(/^"/, "").replace(/"$/,"") ] = url;
                }
            }
        
            return links;
        }

    }


    class Error extends React.Component {
        constructor(props) {
            super(props);
        }
        
        render() {
            if (!this.props.error)
                return null;
                
            if (this.props.error.errorMsg)
                return React.createElement('div',{className:"error", key: 'errorMsg'},this.props.error.errorMsg);
                
            return React.createElement('div',{className:'error', key: 'error'},this.props.error.toString());
        }
    }


export { Error };





