import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import React, { useState, useEffect, Component, Fragment, Suspense } from "react"
import { OAuthLogin, AuthCallback } from "./OAuthLogin";
import { SessionProvider, WorkingDialog, LogonForm } from "../symmetryreact";
import { Computer } from "@mui/icons-material";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { JsonComponent } from "../JsonDisplay";
import { RegisterForm } from "./Register";
import { Session } from "../symmetry";

const Paths = {
    root: '/',
    register: '/register',
    authed: '/authed',
    select: '/select',
    logon: '/logon'
  };
  

function fixResponseTypes(reg) {
    const grant_types = reg.grant_types || [];
    return reg.response_types ? reg :
        {
            ...reg, response_types: [
                ...(grant_types.includes('authorization_code')
                    ? ['code']
                    : []), ...((grant_types.includes('implicit')) ? ['token'] : [])]
        };
}

var MobileDialog = Dialog;

function unique(item, index, array) {
    const itemAsString = JSON.stringify(item);
    return array.slice(0, index)
        .filter(i2 => JSON.stringify(i2) == itemAsString)
        .length == 0;
}

class SelectSystemDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { clients: props.clients };
    }

    componentDidMount() {
        const clientJson = localStorage.clients;
        const clients = clientJson ? JSON.parse(localStorage.clients) : [];
        clients.forEach( cl => {
            const slashPos = cl.url.lastIndexOf('/');
            if (slashPos>8 && slashPos!=cl.url.length-1)
                cl.url = cl.url+"/";
        } );
        this.setState({ clients });
    }

    render() {
        const { onSelect, onNew } = this.props;

        const { clients } = this.state;

        if (!clients)
            return <WorkingDialog>Loading client details</WorkingDialog>;

        if (clients.length == 0)
            return <Redirect to={Paths.register} />;

        const clientList = (clients||[]).reduce((map, client) => { map[client.url] = (map[client.url] || []).concat([client.registration]); return map; }, {});

        return <MobileDialog key="selectUserForm" open>
            <DialogTitle>Select System</DialogTitle>
            <DialogContent margin='normal' dividers>
                <List>
                    {Object.getOwnPropertyNames(clientList).map(url =>
                        <Fragment key={url}>
                            <ListItem key={url}>
                                <ListItemText primary={url} />
                            </ListItem>
                            {clientList[url].map((registration,index) =>
                                <Tooltip key={registration.client_id + index.toString()} enterDelay={3000} title={<JsonComponent json={registration} style={{ width: 'auto' }} />} >
                                    <ListItem button key={registration.client_id} onClick={() => onSelect({ url, registration })}>
                                        <ListItemIcon><Computer /></ListItemIcon>
                                        <ListItemText primary={registration.client_name} secondary={registration.client_id} />
                                    </ListItem>
                                </Tooltip>)}
                        </Fragment>
                    )}
                    <ListItem button onClick={() => onNew()}>
                        <Typography>Add a new host...</Typography></ListItem>
                </List>
            </DialogContent>
        </MobileDialog>
    }
}

export function Authenticated({children,fallback}) {
    const [session, setSession] = useState();
    const [clients, setClients] = useState();
    const [client, setStateClient] = useState();
    const [redirectTo, setRedirectTo] = useState(false);
    const [referer, setReferer] = useState();

    const setClient = (cl) => {
        console.trace( JSON.stringify( {setclient:cl}) );
        const reordered = [cl].concat(clients.filter( c => c!=cl ));
        localStorage.clients = JSON.stringify( reordered );
        setClients( reordered );
        setStateClient( cl );

    };

    useEffect(() => {
        if (!clients) {
            const clientsJson = localStorage.clients;
            if (clientsJson)
            {
                const loadedClients = JSON.parse(clientsJson)
                        .map(cl => { return { ...cl, registration: fixResponseTypes(cl.registration) }; })
                        .filter(unique);

                setClients(loadedClients);
                if (loadedClients.length==1)
                    setStateClient(loadedClients[0]);
            }
        }
    }, [clients]);

    if (redirectTo)
    {
        setRedirectTo(false);
        window.location.href = redirectTo;
        return null;
    }

    const onAuth = (auth)  => {
        const sess = new Session({url:client.url} , auth);
        sess.onLogoff = () => setSession(false);
        const { state } = auth;
        const savedState1 = sessionStorage.getItem(state);
        if (savedState1) {
            const savedState2 = JSON.parse(savedState1);
            setReferer(savedState2.referer);
            sessionStorage.removeItem(state);
        }

        setSession(sess);
    }

    if (!session)
        return <BrowserRouter>
            <Switch>
                <Route path={Paths.register}>
                    <RegisterForm onDone={()=>setRedirectTo(Paths.logon)} />
                </Route>

                <Route path={Paths.authed}>
                         <AuthCallback onAuth={onAuth} />
                </Route>
                <Route path={Paths.select}>
                    {client && <Redirect to={Paths.root} />}
                    <SelectSystemDialog
                        clients={clients}
                        onSelect={setClient}
                        onNew={() => setRedirectTo(Paths.register)} />
                </Route>
                <Route>
                    { !client ?
                        <Redirect to={Paths.select} />
                        /*: <OAuthLogin client={client} onAuth={onAuth}
                        onError={err => this.setState({ useOAuth: false })} />
                        */
                       : <LogonForm {...{client,onAuth}} />
                    }
                </Route>
                { /*
                <Route>
                    <OAuthLogin onAuth={onAuth} onError={err => this.setState({useOAuth:false}) } />
                </Route>
                */ }
            </Switch>
        </BrowserRouter>;

    return <BrowserRouter>
        <Route path={Paths.authed}>
            <Redirect to={referer || Paths.root}/>
        </Route>
        <Route>
            { session ?
            <Suspense fallback={fallback || <WorkingDialog>Loading...</WorkingDialog>}>
                <SessionProvider session={session}>
                    {children}
                </SessionProvider>
            </Suspense> : <WorkingDialog>Loading (B)...</WorkingDialog> }
            
        </Route>
    </BrowserRouter>;
}
