import React from 'react'
import { Button, CircularProgress } from '@mui/material'

const BusyButton = (props) => {
  const butProps = { ...props, busy: undefined };
  return props.busy ?
    <div style={{ position: 'relative', display: 'inline-block' }} >
      <Button disabled {...butProps}>{props.children}</Button>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-0.75em,-0.75em)', width: '1.5em', height: '1.5em' }}>
        <CircularProgress size='1.5em' color={props.color} />
      </div>
    </div>
    : <Button color={props.color} {...butProps}>{props.children}</Button>
};

export { BusyButton }
